<template>
    <base-card id="info-links-page" class="pa-3">
        <v-skeleton-loader v-if="selectAppSettingLoading"
            height="100px"
            type="image"
        ></v-skeleton-loader>
        <div v-if="!selectAppSettingLoading" class="tip-tap-content" v-html="model.option_value"></div>
    </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    metaInfo: {
        title: "Confidentiality & Info",
    },
    data: (vm) => ({
        model: {
            option_value: null
        },
    }),    
    computed: {
        ...mapGetters(["selectedAppSettingModel", "selectAppSettingLoading"]),
    },
    watch: {
        selectedAppSettingModel: function(newVal) {
            this.model = {...this.model, ...newVal}
        }
    },
    created() {
        this.getSelectAppSettingData('LANDING_PAGE').then(resp =>  this.model = {...this.model, ...resp.data.data});
    },
    methods: {
        ...mapActions(['getSelectAppSettingData']),
    }
}
</script>
